import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { InventoryTrimSummaryEntities } from 'client/data/models/inventory-core-configurations';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { getBuildAndPriceLink } from 'site-modules/shared/utils/build-and-price-link-constructor';
import { getPriceBarSettings, getPriceBarWidth } from 'site-modules/shared/utils/core-page/pricing-bars';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';
import { Link } from 'site-modules/shared/components/link/link';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { PricingAd } from 'site-modules/shared/components/native-ad/pricing-ad/pricing-ad';

export const MAX_TRIMS_COUNT = 7;
export const BAR_HEIGHT = 32;
export const CARD_HEIGHT = 100;
const PRICING_AD_INDEX = 2;

export function TrimBarsList({
  params,
  submodelIdentifier,
  trimSummaries,
  recommendedTrim,
  barClassName,
  displayLeadForm,
  isMobile,
  pricingAdPos,
}) {
  const priceBarSettings = getPriceBarSettings(trimSummaries);
  const pricingAdIndex = Math.min(PRICING_AD_INDEX, trimSummaries.length - 1);

  return (
    <ul className="trim-bars-list list-unstyled pl-0">
      {trimSummaries.map(
        (
          {
            id,
            trim,
            trimSlug,
            suggestedPrice,
            totalMsrp,
            baseMsrp,
            isMostPopular,
            hasLeadFormDealers,
            inventoryCount,
          },
          index
        ) => {
          const ctaId = `cta-${id}`;
          const priceId = `price-${id}`;
          const displayPrice = suggestedPrice || totalMsrp || baseMsrp;
          const CardTag = hasLeadFormDealers ? 'button' : Link;
          const cardCta = hasLeadFormDealers ? 'Compare dealer prices' : 'What should I pay';
          const isRecommended = trimSlug === recommendedTrim;

          const cardProps = hasLeadFormDealers
            ? {
                'data-index': index,
                'data-tracking-id': 'view_pricing_bar_details',
                onClick: displayLeadForm,
              }
            : {
                to: inventoryCount
                  ? srpLinkBuilder({
                      make: params.make,
                      model: params.model,
                      submodel: params.submodel,
                      year: params.year,
                      inventorytype: INVENTORY_TYPES_LOWERCASE.NEW,
                      submodelid: params.submodel && submodelIdentifier,
                      trim: trimSlug,
                    })
                  : getBuildAndPriceLink(params),
                'data-tracking-id': inventoryCount
                  ? 'view_pricing_bar_srp_details'
                  : 'view_pricing_bar_configure_details',
                rel: 'nofollow',
              };

          const ctaProps = hasLeadFormDealers
            ? {
                'data-tracking-id': 'pricing_bar_text_link',
              }
            : {
                'data-tracking-id': inventoryCount ? 'pricing_bar_text_srp_link' : 'pricing_bar_text_configure_link',
              };

          return (
            <Fragment key={id}>
              {index === pricingAdIndex && (
                <li className="mb-1">
                  <PricingAd
                    mobile={isMobile}
                    position={pricingAdPos || undefined}
                    showHorizontalLayout
                    showOnlySiteServed
                  />
                </li>
              )}
              <li className="fw-medium medium mb-1">
                {isMobile && index === MAX_TRIMS_COUNT - 2 && <ScrollElement id="scrollTop-pricing" />}
                <CardTag
                  {...cardProps}
                  className="card-wrapper d-block w-100 border-0 text-decoration-none text-start rounded bg-white px-0_75 py-0_5"
                  aria-labelledby={`${ctaId} ${priceId}`}
                  data-tracking-value={`${index + 1} - ${trimSummaries.length}`}
                >
                  <div className="mb-0_25 text-gray-darker fw-bold d-flex align-items-baseline">
                    {trim}{' '}
                    {!!(isMostPopular || isRecommended) && (
                      <div className="text-success ml-1 small fw-bold" data-tracking-id="click_trim_label">
                        {isRecommended ? "Editors' Pick" : 'Most Popular'}
                      </div>
                    )}
                  </div>
                  <div
                    className={classnames(
                      'd-block bar-wrapper w-100 pos-r rounded overlay-white-40 overflow-hidden fw-medium border-0 mb-0_5',
                      barClassName
                    )}
                    style={{ height: `${BAR_HEIGHT}px` }}
                  >
                    <div
                      id={priceId}
                      className="bar pos-a top-0 left-0 h-100 bg-blue-10"
                      style={{ width: `${getPriceBarWidth({ priceBarSettings, suggestedPrice: displayPrice })}%` }}
                    >
                      <div className="pos-a center-y right-0 mr-1 text-white">
                        <span className="visually-hidden">{suggestedPrice ? 'Suggested price' : 'MSRP'}:&nbsp;</span>
                        {getPriceString(displayPrice)}
                      </div>
                    </div>
                  </div>
                  <div
                    {...ctaProps}
                    id={ctaId}
                    className="cta-text text-end text-primary-darker"
                    data-tracking-value={`${index + 1} - ${trimSummaries.length} - ${cardCta}`}
                  >
                    {cardCta}
                    <span className="visually-hidden">&nbsp;for {trim} trim</span>
                    <i className="icon-arrow-right3 size-10 d-inline-block ml-0_25" aria-hidden />
                  </div>
                </CardTag>
              </li>
            </Fragment>
          );
        }
      )}
    </ul>
  );
}

TrimBarsList.propTypes = {
  params: CorePageParams.isRequired,
  trimSummaries: InventoryTrimSummaryEntities.TrimSummaries.isRequired,
  displayLeadForm: PropTypes.func.isRequired,
  recommendedTrim: PropTypes.string,
  submodelIdentifier: PropTypes.string,
  barClassName: PropTypes.string,
  isMobile: PropTypes.bool,
  pricingAdPos: PropTypes.string,
};

TrimBarsList.defaultProps = {
  recommendedTrim: null,
  submodelIdentifier: null,
  barClassName: 'bg-blue-90',
  isMobile: false,
  pricingAdPos: null,
};
