import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'site-modules/shared/components/link/link';

export function LookingForModel({ model }) {
  if (!model) {
    return null;
  }

  const { vehicleName, href } = model;

  // inline styles used to avoid additional critical css key
  return (
    <div
      className="looking-for-model bg-cool-gray-90"
      style={{
        borderRadius: '8px',
        marginBottom: '0.5rem',
        padding: '0.25rem 0.5rem',
        display: 'flex',
      }}
      data-tracking-parent="looking-for-model"
    >
      <i className="icon-info text-cool-gray-50" style={{ marginRight: '0.75rem', marginTop: '3px' }} aria-hidden />{' '}
      <span>
        Looking for the{' '}
        <Link to={href} className="text-blue-50" data-tracking-id="honda_hybrid_link">
          {vehicleName}
        </Link>
        ?
      </span>
    </div>
  );
}

LookingForModel.propTypes = {
  model: PropTypes.shape({
    vehicleName: PropTypes.string,
    href: PropTypes.string,
  }),
};

LookingForModel.defaultProps = {
  model: null,
};
