import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { InventoryTrimSummaryEntities } from 'client/data/models/inventory-core-configurations';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';

const LABEL_MAP = {
  [true]: 'Trim',
  [false]: 'Select a trim',
};

export function TrimDropdownSelector({ onChange, trims, selectedTrim, isCore6334Enabled }) {
  if (!trims || !trims.length || (trims.length === 1 && !trims[0].pricing.baseMsrp) || !selectedTrim) {
    return null;
  }

  return (
    <div className="trim-select-dropdown pos-r">
      <StyledSelect
        value={get(selectedTrim, 'trim')}
        onChange={onChange}
        name="select-trim"
        valueKey="trim"
        labelKey="label"
        labelText={LABEL_MAP[isCore6334Enabled]}
        wrapperClassName="label-inside w-100"
        labelClassName="text-gray-darker small mb-0_5"
        overrideClasses="trim-select d-block w-100 pl-1 pr-1_75 pt-1_75 pb-0_5 bg-white text-black text-truncate size-16 rounded-8"
        options={trims}
        data-tracking-id="trim_select"
      />
      <div
        role="presentation"
        className="pos-a text-truncate bg-white"
        style={{
          pointerEvents: 'none',
          left: '1rem',
          right: '1.75rem',
          bottom: '0.5rem',
        }}
      >
        {get(selectedTrim, 'displayLabel')}
      </div>
    </div>
  );
}

TrimDropdownSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTrim: InventoryTrimSummaryEntities.TrimSummary,
  trims: InventoryTrimSummaryEntities.TrimSummaries,
  isCore6334Enabled: PropTypes.bool,
};

TrimDropdownSelector.defaultProps = {
  selectedTrim: null,
  trims: [],
  isCore6334Enabled: false,
};
