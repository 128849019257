import React from 'react';
import PropTypes from 'prop-types';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { SubmodelDropdown } from './submodel-dropdown';
import { YearDropdown } from './year-dropdown';
import { ModelFamilyDropdown } from './model-family-dropdown';

import './year-submodel-controls.scss';

const DESCRIPTION_ID = 'year-submodel-description';

export function YearSubmodelControls({ params, vehicle, isCore6334Enabled }) {
  const selectedYearId = `${vehicle.modelYearId}`;
  const selectedSubmodelId = `${vehicle.year}|${vehicle.submodels.slug}`;
  const selectedModelSlug = `${vehicle.model.slug}`;

  return (
    <div className="year-submodel-controls d-flex justify-content-between align-items-start">
      <span id={DESCRIPTION_ID} className="visually-hidden">
        Changing year or type will take you to a new page
      </span>
      <YearDropdown
        descriptionId={DESCRIPTION_ID}
        params={params}
        modelName={vehicle.model.name}
        value={selectedYearId}
        isCore6334Enabled={isCore6334Enabled}
      />
      <SubmodelDropdown
        descriptionId={DESCRIPTION_ID}
        params={params}
        value={selectedSubmodelId}
        isCore6334Enabled={isCore6334Enabled}
      />
      {!params.isTruck && (
        <ModelFamilyDropdown
          descriptionId={DESCRIPTION_ID}
          params={params}
          value={selectedModelSlug}
          isCore6334Enabled={isCore6334Enabled}
        />
      )}
    </div>
  );
}

YearSubmodelControls.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  isCore6334Enabled: PropTypes.bool,
};

YearSubmodelControls.defaultProps = {
  isCore6334Enabled: false,
};
