import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildTrimSummariesPath,
  InventoryCoreConfigurationsModel,
} from 'client/data/models/inventory-core-configurations';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { Link } from 'site-modules/shared/components/link/link';

const CREATIVE_ID = 'edm-entry-pricing';

export function PricingCtaUI({ hasLeadFormDealers, buildAndPriceLink, srpLink }) {
  useEffect(() => {
    if (hasLeadFormDealers === null) {
      return;
    }

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        subaction_name: TrackingConstant.DISPLAY_ORDER,
        creative_id: CREATIVE_ID,
        value: hasLeadFormDealers ? 'Build & price' : 'See all for sale',
      },
    });
  });

  const linkProps = hasLeadFormDealers
    ? {
        to: buildAndPriceLink,
        'data-tracking-id': 'configure_vehicle',
        rel: 'nofollow',
      }
    : {
        to: srpLink,
        'data-tracking-id': 'pricing_cards_srp_button',
      };

  return (
    <Link {...linkProps} className="text-blue-40 py-0_25" data-tracking-parent={CREATIVE_ID}>
      {hasLeadFormDealers ? 'Build & Price' : 'See all for sale'}
      <i className="icon-arrow-right3 xsmall ml-0_25" aria-hidden />
    </Link>
  );
}

PricingCtaUI.defaultProps = {
  hasLeadFormDealers: PropTypes.bool,
  buildAndPriceLink: PropTypes.string,
  srpLink: PropTypes.string,
};

PricingCtaUI.defaultProps = {
  hasLeadFormDealers: null,
  buildAndPriceLink: null,
  srpLink: null,
};

export const PricingCta = connectToModel(PricingCtaUI, {
  hasLeadFormDealers: bindToPath(
    ({ vehicle }) => buildTrimSummariesPath(getParamsFromVehicle(vehicle)),
    InventoryCoreConfigurationsModel,
    trimSummaries => get(trimSummaries, '[0].hasLeadFormDealers')
  ),
});
