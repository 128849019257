import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, flow } from 'lodash';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  VehicleModel,
  VehicleEntities,
  buildMakeModelDefaultYear,
  buildMmyModelFamilyIdsPath,
  buildModelFamilyIdsModelYearsPath,
} from 'client/data/models/vehicle-v2';
import { PUB_STATES } from 'client/constants/pub-states';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { getFilteredModelYears } from 'site-modules/shared/utils/core-page/model-year-helpers';
import { venomHistory } from 'client/utils/history/venom-history';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { SpeculationRule } from 'site-modules/shared/components/speculation-rule/speculation-rule';

const LABEL_MAP = {
  [true]: 'Year',
  [false]: 'Select year',
};

function YearDropdownUI({ newModelYears, defaultYear, value, descriptionId, isCore6334Enabled }) {
  const [hasSpeculation, enableSpeculation] = useToggle(false, { limit: 1 });

  const selectYear = useCallback(({ url }) => {
    venomHistory.push(url);
  }, []);

  if (isEmpty(newModelYears)) {
    return null;
  }

  const newYears = newModelYears.map(({ id, publicationStates, year, makeSlug, modelSlug }) => ({
    id: `${id}`,
    label: publicationStates.includes(PUB_STATES.PRE_PROD) ? `${year} - Upcoming` : `${year} - New`,
    url: getCoreUrl({ makeSlug, modelSlug, year, defaultYear }),
  }));
  const speculationUrls = newYears.filter(({ id }) => id !== value).map(({ url }) => url);

  return (
    <Fragment>
      <StyledSelect
        value={value}
        onChange={selectYear}
        onClick={enableSpeculation}
        name="select-year"
        valueKey="id"
        labelText={LABEL_MAP[isCore6334Enabled]}
        wrapperClassName="label-inside mr-1 mb-1_5 w-100"
        labelClassName="small mb-0_5"
        overrideClasses="year-select d-block w-100 px-1 pt-1_75 pb-0_5 size-16 bg-white text-black rounded-8"
        options={newYears}
        data-tracking-id="year_select"
        aria-describedby={descriptionId}
      />
      {hasSpeculation && <SpeculationRule urls={speculationUrls} />}
    </Fragment>
  );
}

YearDropdownUI.propTypes = {
  newModelYears: PropTypes.arrayOf(VehicleEntities.ModelYearByFamilyId),
  defaultYear: PropTypes.number,
  value: PropTypes.string,
  descriptionId: PropTypes.string,
  isCore6334Enabled: PropTypes.bool,
};

YearDropdownUI.defaultProps = {
  newModelYears: null,
  defaultYear: null,
  value: '',
  descriptionId: null,
  isCore6334Enabled: false,
};

export const YearDropdown = flow(
  component =>
    connectToModel(component, {
      defaultYear: bindToPath(
        ({ params }) => buildMakeModelDefaultYear({ make: params.make, model: params.model }),
        VehicleModel
      ),
      newModelYears: bindToPath(
        ({ modelFamilyIds }) => !isEmpty(modelFamilyIds) && buildModelFamilyIdsModelYearsPath(modelFamilyIds),
        VehicleModel,
        (modelYears, { modelName }) =>
          getFilteredModelYears(
            modelYears.filter(({ publicationStates }) => !publicationStates.includes(PUB_STATES.USED)),
            modelName
          )
      ),
    }),
  component =>
    connectToModel(component, {
      modelFamilyIds: bindToPath(
        ({ params }) => buildMmyModelFamilyIdsPath({ make: params.make, model: params.model, year: params.year }),
        VehicleModel
      ),
    })
)(YearDropdownUI);
