export const LOOKING_FOR_MODEL_MAP = {
  honda: {
    'accord-hybrid': {
      '2022': {
        vehicleName: '2023 Honda Accord Hybrid',
        href: '/honda/accord/2023/hybrid/',
      },
    },
    'cr-v-hybrid': {
      '2022': {
        vehicleName: '2023 Honda CR-V Hybrid',
        href: '/honda/cr-v/2023/hybrid/',
      },
    },
  },
};
